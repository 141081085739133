<template>
    <div class="iq-card">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title text-center">
          <h4>
            Leaderboard
          </h4>
        </div>
      </div>
      <div class="row iq-card-body pt-0">
        <div class="col-12" v-if="propQuizResultDetail">
          <div class="iq-card p-0" >
            <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title text-center w-100">
              <h6 class="card-title text-center">You vs Topper</h6>
            </div>
            </div>
            <div id="chartdiv" style="height:100%"></div>
          </div>
        </div>
        <div class="col-12">
          <div class="iq-card p-0" style="padding-top: 16px;">
            <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title text-center w-100">
                <h6 class="card-title">Top Candidates</h6>
              </div>
            </div>
            <div class="iq-card-body pt-0 w-100">
              <div class="leaderBoardList row m-0">
                <li class="list-group-item mt-0 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 w-100 justify-content-between align-items-center" v-for="(item, index) of leaderUserList" :key="index+'quiz'">
                  <h6> {{item.user_name}}</h6>
                  <p class="mb-0">
                    Skill Quotient: {{item.aprs_percentile ? item.aprs_percentile : '-'}}
                    | Rank :  {{item.aprs_rank ? item.aprs_rank : '-'}}
                  </p>
                  <!-- <span class="badge badge-primary badge-pill">{{item.aprs_percentile}}</span> -->
                </li>

                <!-- Showing Top 10 only. Infinite Loader has been Switched Off -->
                <!-- <b-col class="gide_spiner_center_align" lg="12">
                  <div v-if="!allLoaded" v-infinite-scroll="getQuizLeaderBoard" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                    <b-spinner variant="primary" label="Spinning" >
                    </b-spinner>
                  </div>
                </b-col> -->

              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
    </div>
</template>
<style lang="scss" scoped>
.leaderboard {
  border-radius: 10px;
  box-shadow: 0 7px 30px rgba(62, 9, 11, .3);

  h4 {
    padding: 12px 13px 18px;
    padding-left: 0;

    & svg {
      width: 25px;
      height: 21px;
      position: relative;
      top: 3px;
      margin-right: 6px;
      vertical-align: baseline;
    }

  }
  .leaderBoardList {
    max-height: 452px;
    height: 100%;
    overflow: auto;
  }
}
</style>
<script>
import { AppResults } from "../FackApi/api/appResults.js"
import ApiResponse from "../Utils/apiResponse.js"
import * as am4core from "@amcharts/amcharts4/core"
import * as am4charts from "@amcharts/amcharts4/charts"

export default {
  name: "GideQuizLeaderBoard",
  props: {
    propAppId: {
      default: null
    },
    propToalUsers: {
      default: 0
    },
    propQuizResultDetail: {
      default: null
    }
  },
  components: {
  },
  data () {
    return {
      leaderUserList: [],
      appId: null,
      chart: null,
      limit: 10,
      totalLoaded: 0,
      allLoaded: false,
      busy: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    // this.appId = this.$route.params.appId
    this.appId = this.propAppId
    this.getQuizLeaderBoard()
  },
  methods: {
    /**
     * getQuizLeaderBoard
     */
    async getQuizLeaderBoard () {
      this.busy = true
      if (this.allLoaded) {
        return
      }

      const resultResp = await AppResults.appResultLeaderBoard(this, this.appId, this.limit, this.leaderUserList.length)
      if (!resultResp.resp_status) {
        this.busy = false
        this.allLoaded = true
        return ApiResponse.responseMessageDisplay(this, resultResp)
      }

      this.leaderUserList = [...this.leaderUserList, ...resultResp.resp_data.data]

      if (this.totalLoaded === 0) {
        this.genrateChart()
      }
      this.totalLoaded += this.leaderUserList.length
      this.busy = false
    },
    /**
     * genrateChart
     */
    async genrateChart () {
      if (!this.propQuizResultDetail) {
        return
      }

      this.chart = am4core.create("chartdiv", am4charts.XYChart)
      this.chart.data.push(this.propQuizResultDetail)
      this.chart.data.push(this.leaderUserList[0])
      // Create axes
      let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = "user_name"
      categoryAxis.title.text = "User"

      let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.title.text = "Percentile"

      // Create series
      var series = this.chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = "aprs_percentile"
      series.dataFields.categoryX = "user_name"

      series.name = "Score"
      // series.columns.template.tooltipText = `Series: {name}\n Name: {categoryY} \n Percentile: {valueY}`
      series.columns.template.fill = am4core.color("#104547")
    }
  }
}
</script>
