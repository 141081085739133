<template>
  <div class="row" :key="updateResult">
    <div class="col-12 col-sm-12 col-md-12 col-lg-8">
      <div class="col-12">
        <div class="iq-card">
          <div class="iq-card-body">
            <div class="row mb-2" v-if="quizResultDetail.show_playagain && quizResultDetail.app_type == config.QUIZ_TYPE.quiz && !quizResultDetail.app_name.includes(appNameWithSAT)">
              <b-button style="margin: auto; display: block; margin-bottom: 10px; background-color: #e5252c; font-weight: 400;" @click="startQuiz(quizResultDetail.app_id)" variant="danger" >Play Again</b-button>
            </div>

            <b-button variant="primary" @click="gotoLink(testSeriesProductName, quizResultDetail.app_id)" v-if="quizResultDetail.app_type == testSeriesProductName" class="ml-3 float-right">Testseries Dashboard</b-button>

            <div v-if="(userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118') || (quizResultDetail.show_results == 1)">
              <span v-for="(chain, index) of quizResultDetail.chains" :key="index">
                <b-badge
                  style="cursor:pointer; color: #e5252c; background-color: white"
                  class="border mr-2 mb-2 font-weight-normal"
                  variant="none"
                  @click="gotoNextAppResult(chain.app_id)">
                  <span>{{ chain.app_name }}</span>
                </b-badge>
              </span>
            </div>

            <div class="row mt-4">
              <div class="col-8">
                <h4 class="heading mb-1">Quiz Result: {{quizResultDetail.app_name}}</h4>
                <h5 class="heading mb-1">Student Name: <span class="primary-color pointer" @click="gotoUserProfile(gideUserId)">{{ user_name }}</span></h5>
              </div>
              <div class="col-4">
                <span class="badge bg-primary float-right">
                  {{quizResultDetail.app_is_gideplus == 0 ? 'Gide' : 'Gide Plus'}}
                </span>
              </div>
              <div class="col-12 mt-2 mb-2" v-if="showResultInfoMsg">
                <span class="badge border border-danger text-gideColor text-wrap" style="text-align:left;">{{ resultInfoMsg }}</span>
              </div>
              <div class="col-12">
                <p class="detail_head">Last Played on: {{quizResultDetail.modified_on | dateFormatDDMMYYYYHHMMA}}</p>
              </div>
              <div class="col-12" v-if="quizResultDetail.show_rank">
                <p class="detail_head">Duration: {{quizResultDetail.app_duration |durationToDHM }}</p><br>
                <h5 class="detail_head" v-if="quizResultDetail.app_type == config.QUIZ_TYPE.quiz && !quizResultDetail.app_name.includes(appNameWithSAT)">Your Rank is {{quizResultDetail.aprs_rank ? quizResultDetail.aprs_rank : '-' }} out of {{quizResultDetail.total_users}}</h5>
                <h5 class="detail_head" v-else>Your Score is {{quizResultDetail.aprs_score}}</h5>
              </div>

              <div class="mt-4 col-12" v-if="(userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118') || quizResultDetail.show_rank">
                <div class="boxes">
                  <div class="boxs btn mb-1 mr-3 iq-card" style="background: #DC3912 !important;">
                    <span>Score</span><br />
                    <span class="badge badge-light mt-4">{{quizResultDetail.aprs_score}} / {{ quizResultDetail.app_total_score }} </span>
                  </div>
                  <div class="boxs btn mb-1 mr-3 iq-card" style="background: rgb(51, 102, 204) !important;">
                    <span>Percentage</span><br />
                    <span class="badge badge-light mt-4">{{ quizResultDetail.aprs_percentage }} %</span>
                  </div>
                  <div class="boxs btn mb-1 mr-3 iq-card" style="background: rgb(16, 150, 24) !important;" v-if="appCategory && !appCategory.includes(appNameWithSAT)">
                    <span>percentile</span><br />
                    <span class="badge badge-light mt-4">{{ quizResultDetail.aprs_percentile ? quizResultDetail.aprs_percentile + "%" : "-" }}</span>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>

        <b-btn v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" variant="primary" class="mb-3" title="Download detailed report" @click="generatePdf">
          Download Report
        </b-btn>
        <b-btn v-if="quizResultDetail.show_analysis == '1'" variant="primary" class="mb-3 ml-1" title="Show Analysis" @click="analyzeResults()">
          Show Analysis
        </b-btn>

        <!-- Detailed report card -->
        <VueHtml2pdf
          :show-layout="true"
          :float-layout="false"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="(resultOwnerDetails && resultOwnerDetails.user_name + '_' + resultOwnerDetails.user_id.substring(0,10))"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="100%"
          ref="html2Pdf"
          @hasDownloaded="() => {hideBeforePrint = 'display_none'}"
        >
          <div slot="pdf-content">
            <h5 :class="`iq-card p-3 ${hideBeforePrint}`" v-if="resultOwnerDetails"><span class="primary-color">{{resultOwnerDetails.user_name}}</span> Your Score is {{quizResultDetail.aprs_score}} / {{ quizResultDetail.app_total_score }}</h5>
            <div class="iq-card" style="width: 100%" v-for="(quizCategory, index) in distinctQuizCategories" :key="index">
                <div class="iq-card-header d-flex justify-content-between" v-if="quizResultDetail.chains.find(app => app.app_name == quizCategory[0].app_name)">
                  <div class="iq-header-title">
                    <h4 class="card-title" style="display: inline;">Category wise Score [ {{quizCategory[0].app_name}} ]
                      <span v-if="appCategory.includes(appNameWithSAT)">
                        <b-badge
                          style="cursor:pointer; color: #e5252c; background-color: white"
                          class="border mr-2 mb-2 font-weight-normal my-auto"
                          variant="none">
                          <span v-if="quizResultDetail.chains.find(app => app.app_id == quizCategory[0].app_id)">scores: {{ satScoreCalculation(quizResultDetail.chains.find(app => app.app_id ==quizCategory[0].app_id).app_score, quizResultDetail.chains.find(app => app.app_id ==quizCategory[0].app_id).app_total_score, quizResultDetail.chains.length) }} / {{ quizResultDetail.chains[Object.keys(distinctQuizCategories).indexOf(index)].app_total_score }}</span>
                      </b-badge>
                    </span>
                  </h4>
                </div>
              </div>
              <div class="iq-card-body pt-0 pl-3" v-if="quizResultDetail.chains.find(app => app.app_name == quizCategory[0].app_name)">
                <p class="pl-1">Assessment of your performance on this quiz based on different categories</p><br>
                  <div class="col-8 pl-0">
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(item) of quizCategory" :key="item.id">
                    {{ item.qbk_category ? item.qbk_category : "-" }}
                    <span class="badge badge-primary badge-pill">{{ item.correct_answer }}
                      / {{item.qbk_category ? item.qbk_category_total_question : item.qbk_total_question}}
                    </span>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </VueHtml2pdf>

        <!-- Detailed report card -->
        <div class="iq-card p-3" v-if="Object.keys(distinctQuizCategories).length == 0">
          <h5 v-if="isSATaTHON">Thank you for attempting the quiz competition, we are evaluating your results. For a detailed report of your performance kindly wait for 48hrs. For any other support please contact <br><br>Email:&nbsp;<a class="pointer primary-color" href="mailto:support@geniuszen.com">support@geniuszen.com</a><br>Tel:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a class="pointer primary-color" href="tel:+919958879749">+91-9958879749</a></h5>
          <h5 v-else>Thank you for attempting the quiz competition, we are evaluating your results. For a detailed report of your performance kindly wait for 48hrs. For any other support please contact <a class="pointer primary-color" href="mailto:support@gide.ai">support@gide.ai</a></h5>
        </div>

        <div class="iq-card" v-if="answerCategoryResult">
          <div class="iq-card-header d-flex justify-content-between">
            <div class="iq-header-title">
              <h4 class="card-title">
                Career Analysis
              </h4>
            </div>
          </div>
          <div class="iq-card-body pt-0 pl-3">
            <p class="pl-1">According to our analysis, following careers suits your interests the best</p><br>
            <div class="col-8 pl-0">
              <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(item) of answerCategoryResult" :key="item.id">
                {{ item.answer_category }}
                <span class="badge badge-primary badge-pill">
                  {{item.weightage}}
                </span>
              </li>
            </div>
          </div>
        </div>

        <div v-if="quizResultDetail.chains && quizResultDetail.chains.length > 0 && isSATaTHON && (userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118')" :key="updateResultInsights">
          <QuizResultsInsightsVue :propUserDetails="resultOwnerDetails" :propResultChainList="quizResultDetail.chains" :propAppIsATHON="isSATaTHON"/>
        </div>

        <div class="" v-if="quizResultDetail.show_leaderboard && appId && quizResultDetail.total_users && quizResultDetail.app_type == config.QUIZ_TYPE.quiz && !quizResultDetail.app_name.includes(appNameWithSAT)">
          <GideQuizLeaderBoardVue :propAppId="appId" :propToalUsers="quizResultDetail.total_users" :propQuizResultDetail="quizResultDetail"></GideQuizLeaderBoardVue>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4 gide_right_mobile_data" v-if="!isMobileDevice">
      <div class="iq-card iq-card-block iq-card-stretch blog-post">
        <div class="iq-card-header d-flex justify-content-between">
          <div class="header-title">
            <h4 class="iq-card-title">
              Upcoming Quiz
            </h4>
          </div>
        </div>
        <div class="iq-card-body" v-if="examList && examList.length > 0">
          <ul class="list-inline p-0 mb-0 mt-2">
            <li class="mb-3 pointer" v-for="(quiz, index) in examList" :key="index+'QiozLIST'">
              <div class="d-flex align-items-top pb-3 border-bottom" @click="QuizDetail(quiz.app_id)">
                <div class="col-md-5">
                  <div class="image-block" style="height: 80px;" v-if="quiz.app_image">
                    <img :src="quiz.app_image" class="img-fluid rounded w-100" style="height: 80px;" alt="blog-img">
                  </div>
                  <div v-else style="background-color: #EEEEEE; width: 100%; height: 80px; border-radius: 5px; display: flex; text-align: center;">
                    <span style="margin: auto; font-size: 36px;">{{ quiz.app_name.slice(0, 1) }}</span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="blog-description pl-2">
                    <div class="date mb-1">
                      <a style="cursor: pointer;" tabindex="-1">
                        {{quiz.app_date_ts | dateFormatDDMMYYYYHHMMA}}
                      </a>
                    </div>
                    <h6 class="mb-2">
                      {{ quiz.app_name }}
                    </h6>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="iq-card-body" v-else>New Quiz coming soon...</div>
      </div>
      <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)" />
    </div>
  </div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import { AppResults } from "../../../FackApi/api/appResults.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import EventHomeList from "../Event/EventHomeList.vue"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import { Apps } from "../../../FackApi/api/apps.js"
import GideQuizLeaderBoardVue from "../../../components/GideQuizLeaderBoard.vue"
import config from "../../../Utils/config.js"
import userRoles from "../../../FackApi/json/UserRoles.json"
import QuizResultsInsightsVue from "../../../components/QuizResultsInsights.vue"
import { User } from "../../../FackApi/api/user.js"
import VueHtml2pdf from "vue-html2pdf"
import GideProducts from "../../../FackApi/json/GideProducts.json"

export default {
  name: "QuizResultView",
  components: {
    EventHomeList,
    GideQuizLeaderBoardVue,
    QuizResultsInsightsVue,
    VueHtml2pdf
  },
  data () {
    return {
      VUE_APP_ENV: process.env.VUE_APP_ENV,
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      quizCategoryList: [],
      quizResultDetail: {},
      updateResult: 0,
      updateResultInsights: 0,
      appId: null,
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList(),
      examList: [],
      config: config,
      showResultInfoMsg: false, // false: dont Show, true: Show:: info message on top of the page
      distinctQuizCategories: {},
      appCategory: "",
      totalScore: 0,
      userRoles: userRoles,
      isSATaTHON: null,
      appNameWithSAT: "SAT-",
      user_name: null,
      resultOwnerDetails: null,
      answerCategoryResult: null,
      hideBeforePrint: "display_none",
      resultInfoMsg: "Final results shall be published in 48hrs after end of this competition. You can also access the results from Quiz Result's link on Fun Menu Screen",
      gideUserId: null,
      chained_apps_total_score: 0,
      testSeriesProductName: GideProducts.TESTSERIES
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  async mounted () {
    socialvue.index()
    this.appId = this.$route.params.appId
    this.gideUserId = this.$route.params.userId
    this.getResultDetail()

    let userViewResp = await User.userView(this, this.gideUserId, this.userData.user_id)
    if (userViewResp.resp_status) {
      this.resultOwnerDetails = userViewResp.resp_data.data
      this.user_name = this.resultOwnerDetails.user_name ? this.resultOwnerDetails.user_name : this.resultOwnerDetails.user_email
    }
  },
  methods: {
    /**
     * goto Link
     */
    gotoLink (url, appId) {
      if (url == this.testSeriesProductName) {
        url = `/result_list/${appId}`
      }

      this.$router.push(url)
    },
    /**
     * generate Pdf
     */
    generatePdf () {
      this.hideBeforePrint = ""
      this.$refs.html2Pdf.generatePdf()
    },
    /**
     * analyze Results
     */
    analyzeResults () {
      this.$router.push(`/analyse_app_result/${this.appId}/${this.gideUserId}?attempt=NA`)
    },
    /**
     * goto User Profile
     */
    gotoUserProfile (gideUserId) {
      window.open(`/user/${gideUserId}`)
    },
    /**
     * getResultDetail
     */
    async getResultDetail () {
      let payload = { app_id: this.appId }

      if (this.userData.user_id != this.gideUserId && (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118")) {
        // Gide Admin trying to view and analyse the result. So send the student users id in the payload to get the results from the server
        payload.other_user_id = this.gideUserId
      }

      let appResultViewResp = null
      if (this.$route.path.split("/").includes("app_result_view_all") && this.$route.params.attemptNo) {
        payload.attempt = this.$route.params.attemptNo
      }
      appResultViewResp = await AppResults.appResultView(this, payload)

      if (!appResultViewResp.resp_status) {
        ApiResponse.responseMessageDisplay(this, appResultViewResp)
        return
      }

      appResultViewResp.resp_data.data.user_name = this.userData.user_name
      this.quizCategoryList = appResultViewResp.resp_data_qbkCategoryList
      this.quizResultDetail = appResultViewResp.resp_data.data
      this.appCategory = this.quizResultDetail.app_category
      this.isSATaTHON = this.appCategory.includes(this.appNameWithSAT)

      if (this.isSATaTHON) {
        // Storing the adjusted scores of SAT in the objects of results in chains array
        this.totalScore = 0

        // calculate the chained_apps_total_score first because it is required in satScoreCalculation function below
        this.chained_apps_total_score = this.quizResultDetail.chains.reduce((acc, itr) => {
          return acc + itr.app_total_score
        }, 0)

        this.quizResultDetail.chains.map((result) => {
          let adjustedScore = this.satScoreCalculation(result.app_score, result.app_total_score, this.quizResultDetail.chains.length)
          this.totalScore += adjustedScore
          return adjustedScore
        })
        this.quizResultDetail.aprs_score = this.totalScore
      }

      this.distinctQuizCategories = {}
      if (this.quizCategoryList) {
        for (let quizCategory of this.quizCategoryList) {
          if (!this.distinctQuizCategories[quizCategory.app_id]) {
            this.distinctQuizCategories[quizCategory.app_id] = [quizCategory]
          }
          else {
            this.distinctQuizCategories[quizCategory.app_id].push(quizCategory)
          }
        }
        if ((this.userData.user_role != "USERROLE11111" && this.userData.user_role != "USERROLE11118") && this.quizResultDetail.show_results == 0) {
          this.distinctQuizCategories = {}
        }
      }

      if (this.quizResultDetail.app_type == config.QUIZ_TYPE.competition && this.$route.params.showResultInfoMsg == "1") {
        this.showResultInfoMsg = true // false: dont Show, true: Show:: info message on top of the page
      }

      if (!this.isMobileDevice) {
        this.getExamList(this.quizResultDetail.app_type)
      }
      if (appResultViewResp.resp_data_psychometric) {
        this.answerCategoryResult = appResultViewResp.resp_data_psychometric
      }
      this.updateResultInsights = 1
    },
    /**
      * satScoreCalculation

      *  we are awarding the student 200 marks for no reason per 800 marks in SAT
      => In case of SAT-A-THON, there are 2 quizes and total marks is 1600, so we are awarding (200 / 800) * 1600 = 400 marks
      => We have 2 quizes in SAT-A-THON so we are awarding 400 / 2 = 200 marks on each quiz
      => Below is the sample score calculation for SAT-A-THON
        FOR SAT-A-THON-MATH:
        -> Student Score = 12 Correct Anser. Student Score = (800/22*12)/800*600 = 327.2777
        -> Now Round to nearest multiple of 10 = round(327.277/10)*10 = 330
        -> Now final score = 330+200 = 530 Out of 800
        -> Similar is the case for SAT-A-THON-ENG

      => In case of SAT-MOCK, there are four quizes, and total marks is 1600, so we are awarding (200 / 800) * 1600 = 400 marks
      => We have 4 quizes in SAT-MOCK so we are awarding 400 / 4 = 100 marks on each quiz
      => Below is the sample score calculation for SAT-MOCK
        -> Student score = 12 correct answers. student score = (400/22*12)/400*300 = 163.62
        -> Now Round to nearest multiple of 10 = round(163.62/10)*10 = 164
        -> Now final score = 164+100 = 264 Out of 400
    */
    satScoreCalculation (obtainedScore, appTotalScore, noOfQuizzes = 1) {
      // factor = (200 / 800) * this.chained_apps_total_score

      let factor = ((200 / 800) * this.chained_apps_total_score) / noOfQuizzes
      let finalScore = (obtainedScore / appTotalScore) * (appTotalScore - factor)
      finalScore = Math.floor(finalScore / 10) * 10 + factor
      return finalScore
    },
    /**
     * getExamList
    */
    async getExamList (type) {
      let payload = {
        filter: JSON.stringify({ limit: 5, type: type }),
        upcoming: 1
      }

      const quizListResp = await Apps.appList(this, payload)
      if (!quizListResp.resp_status) {
        ApiResponse.responseMessageDisplay(this, quizListResp)
      }
      this.examList = quizListResp.resp_data.data
    },
    /**
     * QuizDetail
    */
    async QuizDetail (quizId) {
      this.$router.push(`/quiz_detail/${quizId}`)
    },
    /**
     * startQuiz
    */
    async startQuiz (examId) {
      this.$router.push(`/question_quiz/${examId}`)
    },
    /**
     * goToQuizList
     */
    goToQuizList () {
      this.$router.push(`/quiz`)
    },
    /**
     * gotoNextAppResult
     */
    gotoNextAppResult (appId) {
      let path = null
      if (this.$route.path.split("/").includes("app_result_view")) {
        path = `/app_result_view/${appId}/${this.gideUserId}/0` // 0=Dont Show info message on top of the page
      }
      else {
        path = `/app_result_view_all/${appId}/${this.gideUserId}/${this.$route.params.attemptNo}/0` // 0=Dont Show info message on top of the page
      }

      if (this.$route.path != path) {
        this.$router.push(path)
      }
    }
  },
  watch: {
    "$route.path": function () {
      this.appId = this.$route.params.appId
      this.getResultDetail()
      this.updateResult = Math.random()
    }
  }
}
</script>

<style lang="scss" scoped>
  .heading {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .detail_head {
    font-size: 14px;
    margin-bottom: 0px;
    text-transform: capitalize;
    font-weight: 500;
  }
  .iq-card-body p {
    margin-bottom: 0px;
  }
  .boxs span:first-child {
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .boxes{
    text-align:left;
  }
  .display_none {
    display: none;
  }
</style>
