<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h4 class="card-title">
        Upcoming Activities
      </h4>
    </template>
    <hr class="mt-0"/>
    <template v-slot:headerAction v-if="propShowCreateEvent">
      <p class="m-0">
        <router-link to="/event_add">
          Create
        </router-link>
      </p>
    </template>
    <div class="row iq-card-body" v-if="pbaListObj && Object.keys(pbaListObj).length">
      <div class="col-sm-12" v-for="(pba,index) in pbaListObj" :key="index">
        <div class="event-post position-relative" @click="goToPbaView(pba.event_id)">
          <a style="cursor: pointer;">
            <img style="height: 208px; width: 100%" v-if="pbaImgObj && pbaImgObj[pba.event_id] && pbaImgObj[pba.event_id]['cover_pic']" :src="pbaImgObj[pba.event_id]['cover_pic']['image_thumbnail']" class="img-fluid" alt="">
            <div v-else style="display: flex; text-align: center; height: 208px; width: 100%; background-color: #EEEEEE; border-radius: 5px; border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
              <span style="margin: auto; font-size: 80px;">{{getFirstLetterOfAString(pba.event_name)}}</span>
            </div>
          </a>
          <div class="job-icon-position">
            <div class="job-icon bg-primary p-2 d-inline-block rounded-circle">
              <i class="ri-briefcase-line"></i>
            </div>
          </div>
          <div class="iq-card-body text-left p-2 pb-0">
            <h5 class="twoLineOnly">
              {{pba.event_name}}
            </h5>
            <p>
              {{getFormattedDate(pba.event_start_date)}}
            </p>
          </div>
          <b-btn class="d-block mb-4 border-light iq-bg-dark" style="width: 100%;" @click="setRsvpForUser(pba)">
            {{pba.event_rsvp_added ? 'Complete Your Registration' :'Register'}}
          </b-btn>
        </div>
      </div>
      <b-btn class="d-block mt-3 border-light iq-bg-dark" style="width: 100%;" @click="goToEventList()">
        <i class="ri-add-line"></i> See All
      </b-btn>

    </div>
    <div v-else class="row iq-card-body">
      <div class="col-12">
        No upcoming events
      </div>
    </div>
    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </iq-card>
</template>
<script>
import { socialvue } from "../../../config/pluginInit"
import { events } from "../../../FackApi/api/events"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
export default {
  name: "events",
  props: {
    "propModuleName": {
      default: null,
      type: String
    },
    "propModuleId": {
      default: null,
      type: String
    },
    "propShowCreateEvent": {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      soicalInfo: [
        {
          name: "My Universities"
        },
        {
          name: "All Universities"
        }
      ],
      pbaImgObj: {},
      pbaListObj: {},
      propShowPBAModal: false,
      propPBAId: null,
      rsvpUserListObj: {},
      showToast: false,
      showModelAskOtpBox: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Event"
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  components: {
  },
  methods: {
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.eventList()
    },
    /**
     * getFormattedDate
     */
    getFormattedDate (datetime) {
      try {
        return moment(datetime).format("llll")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * getResizedText
     */
    getResizedText (text) {
      return text ? text.substr(0, 100) : ""
    },
    /**
     * goToPbaView
     */
    goToPbaView (pbaId) {
      this.$router.push("/event/" + pbaId)
    },
    /*
     * eventList
     * */
    async eventList () {
      try {
        let filter = {
          "limit": 2,
          "upcoming_events": true,
          "module_id": this.propModuleId ? this.propModuleId : null,
          "module_name": this.propModuleName ? this.propModuleName : null,
          "user_role": this.userData.user_role
        }
        let pbaListResp = await events.eventList(this, filter)
        if (pbaListResp && pbaListResp.resp_status) {
          this.pbaListObj = pbaListResp.resp_data.data
          this.pbaImgObj = pbaListResp.event_images
          this.rsvpUserListObj = pbaListResp.rsvp_users
        }
      }
      catch (err) {
        console.error("Exception occurred at eventList() and Exception:", err.message)
      }
    },
    /**
     * setRsvpForUser
     */
    async setRsvpForUser (eve) {
      this.cvLoadingStatus = true
      try {
        let payload = {
          module_obj_id: this.userData.user_id,
          module_name: "STUDENT",
          event_id: eve.event_id,
          event_name: eve.event_name,
          event_is_paid: eve.event_is_paid === 0 ? "0" : eve.event_is_paid,
          user_email: this.userData.user_email,
          user_mobile: this.userData.user_mobile
        }

        if (this.userData.user_role === "USERROLE11115" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0
        ) {
          // @todo shubham If More then one organisation then show model to select org
          // Org
          payload.module_obj_id = Object.keys(this.userData.modules.organisations)[0]
          payload.module_name = "UNIV"
        }
        else if (this.userData.user_role === "USERROLE11116" &&
          this.userData.modules.organizations && Object.keys(this.userData.modules.organizations).length > 0
        ) {
          // Orgs
          payload.module_obj_id = Object.keys(this.userData.modules.organizations)[0]
          payload.module_name = "ORG"
        }

        let pbaRsvpResp = await events.eventRsvpAdd(this, payload)
        if (pbaRsvpResp && pbaRsvpResp.resp_status) {
          this.pba.event_weblink = pbaRsvpResp.resp_data.event_weblink
          this.pba.event_rsvp_added = true
          if (this.pba.event_weblink) {
            window.open(this.pba.event_weblink, "_blank")
          }
        }
        await ApiResponse.responseMessageDisplay(this, pbaRsvpResp)
      }
      catch (err) {
        console.error("Exception occurred at setRsvpForUser() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * goToCreatePba
     */
    goToCreatePba () {
      this.$router.push("/event_add")
    },
    /**
     * goToEventList
     */
    goToEventList () {
      this.$router.push("/events")
    }
  }
}
</script>
