/*eslint-disable */
import SideBarItems from "../FackApi/json/SideBarGide"

let sideBarMenu = {
  sideBarItemsList: {},
  /**
   * getBgColorForLetter
   */
  getSideBarMenuObjList () {
    // If the Obj exists return it else build it
    if(Object.keys(sideBarMenu.sideBarItemsList).length > 0){
      // console.log("XXXX Object.keys(sideBarMenu.sideBarItemsList).length", Object.keys(sideBarMenu.sideBarItemsList).length)
      return sideBarMenu.sideBarItemsList
    }

    // List not found in memory so build it.
    for (let item of SideBarItems) {
      sideBarMenu.sideBarItemsList[item.name] = item
    }

    // console.log("XXX sideBarMenu.sideBarItemsList Built", sideBarMenu.sideBarItemsList)
    return sideBarMenu.sideBarItemsList
  },
}

export default sideBarMenu